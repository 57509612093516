@import "~antd/dist/antd.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* .ant-tabs-nav-list .ant-tabs-tab{
 padding:10px 12px;
 background-color:#cc3300;
 color:#fff;
} */
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: rgba(225, 225, 225, 0.80);
}

.ant-tabs-ink-bar {
  background-color: rgba(0, 0, 0, 0.30);
  height: 3px !important;
}

.ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 2px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  background-color: #f1f1f1;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header {
  background-color: #ffffff;
  border-bottom: 1px solid #dedede;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  z-index: 1;
  width: 100%;
}

.ant-layout-footer {
  position: fixed;
  z-index: 1;
  right: 0;
  width: 100%;
  bottom: 0;
  padding: 10px;
  background: #000000;
  color: #ffffff;
  text-align: center;
}

.ant-layout {
  background-color: #f1f1f1;
}

.brand {
  font-size: 20px;
  margin: 10px 10px 0px;
  margin-top: 10px;
  flex: 1;
  font-weight: 400;
}

.ant-btn {
  border-radius: 5px;
}

.ant-btn-primary {
  color: #fff;
  border-color: #830051;
  background: #830051;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);

}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  border-color: #830051;
  background: #830051;
}

.App-link {
  color: #61dafb;
}

.ant-table-column-title,
th.ant-table-cell,
.edit-ctn .ant-typography,
.delete-ctn,
.ant-dropdown-trigger.ant-table-filter-trigger.ant-dropdown-open span,
.ant-dropdown-trigger.ant-table-filter-trigger.active span,
.ant-dropdown-menu-item.ant-dropdown-menu-item-selected span,
.ant-table-filter-dropdown-btns button.ant-btn-link {
  color: #830051 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: rgba(225, 225, 225, 0.80);
}

.ant-tabs-ink-bar {
  background-color: rgba(0, 0, 0, 0.30);
  height: 3px !important;
}

.ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 2px;
}

.home-container .ant-tabs-tab-btn {
  color: rgba(0, 0, 0) !important;
  font-weight: 500;
}

.home-container .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.home-container .ant-tabs-tab:hover,
.home-container .ant-tabs-tab-btn:focus,
.home-container .ant-tabs-tab-remove:focus,
.home-container .ant-tabs-tab-btn:active,
.home-container .ant-tabs-tab-remove:active {
  color: #830051 !important;
}

.ant-tabs-ink-bar {
  background-color: #830051 !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #830051 !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #830051 !important;
  box-shadow: 0 0 0 2px rgb(131 0 81 / 20%) !important;
  border-right-width: 1px !important;
  outline: 0 !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {

  background-color: rgba(131, 0, 81, 0.2) !important;
}

/*.home-container {
  padding: 20px;
}*/

.fliter-ctn .ant-select {
  margin-right: 10px
}

.fliter-ctn,
.tab-ctn {}

.fliter-ctn span {
  font-weight: 500;
}

.inner-tab-list .ant-tabs-nav::before {
  border-bottom: 0px !important;
}

.ant-tabs-content-holder #funnel {
  width: 80%;
  margin: 0px auto;
  text-align: center;
}

.ant-layout-header {
  padding: 0px !important;
}

.ant-pagination-item-active a,
.ant-pagination-item:hover a {
  color: #830051 !important;
}

.ant-btn {
  border-radius: 5px;
}

.ant-btn-primary {
  color: #fff;
  border-color: #830051;
  background: #830051;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);

}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  border-color: #830051;
  background: #830051;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
  color: #fff;
  border-color: #830051;
  background: #830051;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}


.ant-spin-nested-loading .ant-tabs-tabpane-active .ant-tabs-content-holder {

  min-height: 250px;
}

/* table */


.ant-table-thead th.ant-table-column-has-sorters:hover,
.ant-table-thead th.ant-table-column-sort {
  background: rgba(132, 1, 81, 0.95) !important;
}

.ant-table-thead th {
  background: rgba(132, 1, 81, 1) !important;
}

.edit-ctn .ant-typography,
.delete-ctn,
.ant-dropdown-trigger.ant-table-filter-trigger.ant-dropdown-open span,
.ant-dropdown-trigger.ant-table-filter-trigger.active span,
.ant-dropdown-menu-item.ant-dropdown-menu-item-selected span,
.ant-table-filter-dropdown-btns button.ant-btn-link {
  color: #830051 !important;
}

.ant-table-column-title,
th.ant-table-cell,
.ant-table-column-sorters:hover .ant-table-column-sorter {
  color: #ffffff !important;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-item.ant-dropdown-menu-item-active {
  background: rgba(196, 12, 124, 0.05) !important;
}

.edit-ctn a.ant-typography,
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #8c8c8c !important;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>td {
  border-right: 0px !important;
}

.ant-table-thead>tr>th,
.ant-table-tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
  position: relative;
  padding: 16px 16px;
  overflow-wrap: break-word;
}

.ant-table-tbody>tr>td {
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s;
}

.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #830051 !important;
  border-color: #830051 !important;
}

.ant-input:focus,
.ant-input-focused {
  border-color: #8c8c8c !important;
  box-shadow: 0 0 0 2px rgba(140, 140, 140, 0.2) !important;
  border-right-width: 1px !important;
}

.ant-pagination-item-active,
.ant-pagination-item:focus-visible,
.ant-pagination-item:hover {
  border-color: #830051 !important;
}

.ant-pagination-item-active a,
.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a {
  color: #830051;
}

/*table*/
.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: #8c8c8c !important;
  box-shadow: 0 0 0 2px rgb(140 140 140 / 20%) !important;
  border-right-width: 1px !important;
}

.ant-table-content {
  overflow: auto hidden;
}

.site-layout-content {
  padding: 20px;
}

.back-btn-ctn {
  position: relative;
}

.back-btn-ctn .ant-page-header-heading-left .ant-page-header-back-button {
  font-size: 14px;
}

.ant-page-header-back {
  margin-right: 0px !important;
}

.ant-page-header-back .ant-page-header-back-button {
  padding-right: 16px;
}

.back-btn-ctn .ant-page-header-heading:hover {
  border-bottom: #830051 1px solid;
}

.back-btn-ctn .ant-page-header-heading {
  padding-right: 10px;
  line-height: normal;
  display: inline;
}

.back-btn-ctn .ant-page-header-heading {
  display: inline-block;
}

.back-btn-ctn .ant-page-header-heading {
  display: inline-block;
}

.back-btn-ctn .ant-page-header-heading:hover .ant-page-header-heading-left .ant-page-header-back-button,
.back-btn-ctn .ant-page-header-heading:hover .ant-page-header-heading-extra {
  cursor: pointer;
  color: #830051
}

.back-btn-ctn .ant-page-header-heading .ant-page-header-heading-left,
.back-btn-ctn .ant-page-header-heading .ant-page-header-back .ant-space-item {
  font-size: 12px;
  display: inline-block;
}

.back-btn-ctn .ant-page-header-heading:hover .ant-page-header-heading-left,
.back-btn-ctn .ant-page-header-heading:hover .ant-page-header-back .ant-space-item {
  color: #830051;
  font-size: 12px;
}

.back-btn-ctn .ant-page-header-heading .ant-page-header-heading-extra {
  position: relative;
  top: -7px;
  left: 4px;
  line-height: normal;
  font-size: 14px; }
  .back-btn-ctn  .ant-page-header-heading {margin-top: 7px;}
  .details-ctn{margin-top:20px;box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px; text-align: center; font-weight: bold; font-size: 20px; background-color: rgb(131, 0, 81); color: #fff; border-radius: 10px; border: 1px solid rgb(255, 153, 0); display: inline-block; padding: 15px;}
  .details-ctn .ant-descriptions-item-container span{ color: #f1f1f1; }
  .details-ctn .ant-descriptions-item-label {font-weight: 500;}
  .details-ctn .ant-descriptions-item-content {font-weight: normal;}
  .details-ctn .ant-descriptions-item {text-align: left;}
  .details-ctn .ant-descriptions-item  .ant-descriptions-item-container  { display: inline-block;}
  .details-ctn .ant-descriptions-item:last-child, .details-ctn .ant-descriptions-item:last-child .ant-descriptions-item-container {
    text-align: right;
    display: inline-block;
}

.details-ctn .ant-descriptions-item {
  float: left;
    text-align: left;
    padding-right: 20px;
}
.details-ctn .ant-descriptions-item:last-child { float: right;}
 .header-title{box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px; text-align: center; font-weight: bold; font-size: 20px; background-color: rgb(255, 255, 255); color: rgb(131, 0, 81); border-radius: 10px;
     border: 1px solid rgb(255, 153, 0);
      display: inline-block; padding: 15px;position: absolute;top: 0px;left: 90px;}
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn, .ant-tabs-tab.ant-tabs-tab:hover .ant-tabs-tab-btn  {color: #830051 !important;}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1024px) {
  .header .filterBox {
    min-width: auto;
    width: auto !important;
  }

  .site-layout-content {
    padding: 15px;
  }
}