 
.node {
    cursor: pointer;
}
.node circle {
    fill: #fff;
    stroke: "#4a98e0";
    stroke-width: 1.5px;
}
.node text {
    font: 20px ;
    /* sans-serif; */
}
.link {
    fill: none;
    stroke: #ccc;
    stroke-width: 1.5px;
}

#treeWrapper {
    overflow: hidden;
}